import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

// components can be called from the imported UIkit reference



var bar = document.getElementById('js-progressbar');

UIkit.upload('.js-upload', {

 url: 'assets/2021/tmp/snip/prev.php',
 multiple: false,
 allow: '*.(jpg|jpeg|gif|png)',
 beforeSend: function (environment) {
  console.log('beforeSend', arguments);

  // The environment object can still be modified here.
  // var {data, method, headers, xhr, responseType} = environment;

 },
 beforeAll: function () {
  //console.log('beforeAll', arguments);
 },
 load: function () {
 // console.log('load', arguments);
 },
 error: function () {
  console.log('error', arguments);
 },
 complete: function () {


  $('#prev').css('display','');
  $('#prev').attr('src',arguments[0].response);
  $('#images').val(arguments[0].response);

 },

 loadStart: function (e) {
 // console.log('loadStart', arguments);

  bar.removeAttribute('hidden');
  bar.max = e.total;
  bar.value = e.loaded;
 },

 progress: function (e) {
 // console.log('progress', arguments);

  bar.max = e.total;
  bar.value = e.loaded;
 },

 loadEnd: function (e) {
 // console.log('loadEnd', arguments);

  bar.max = e.total;
  bar.value = e.loaded;
 },

 completeAll: function () {
  //console.log('completeAll', arguments);

  setTimeout(function () {
   bar.setAttribute('hidden', 'hidden');
  }, 1000);


 }

});



var params = (new URL(document.location)).searchParams;
var urlAjax = "assets/2021/tmp/snip/create.php"
if (params.get("id") > 0) {
 localStorage.setItem('pagetitle', '');
 localStorage.setItem('content', '');
 urlAjax = "assets/2021/tmp/snip/update.php"
}




function sendForm()
{

 if($('#pagetitle').val() == ''){
  UIkit.notification('Заполните заголовок');
  return false;
 }
 if($('#images').val() == ''){
 // UIkit.notification('Добавьте обложку');
 // return false;

 }

 localStorage.setItem('pagetitle', '');
 localStorage.setItem('content', '');

 $('#publik').css('display','none');
 $.ajax({
  url: urlAjax,
  type: 'post',
  data: $('#create').serialize()
 }).done(function(data) {
  window.location.href = data;
 });
}




$( "#publik" ).click(function() {
 sendForm();
});


function addEcpDrop() {
 $( ".nored .ecp" ).each(function( index ) {

   $(this).after('<div data-uk-drop><div class="ecp-card uk-card uk-card-body uk-card-default uk-padding-small"><div class="uk-h4">Электронная подпись</div>Дата и время подписи: '+$(this).data("time")+'<br>Владелец сертификата: '+$(this).data("fullname")+'<br> Должность:  '+$(this).data("position")+'<br> Подпись: '+$(this).data("code").substr(1, 25)+'</div></div>');

 });
}

addEcpDrop();


